<template>
    <div class="autoseoTrial">
        <MoreQuestions></MoreQuestions>
    </div>
</template>

<script>
    import MoreQuestions from '../components/block/MoreQuestions';
    export default {
        name: "autoseoTrial",
        components: {
            MoreQuestions
        }
    }
</script>

<style lang="less" scoped>
.autoseoTrial{
    width: 100%;
}
</style>